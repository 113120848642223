import { template as template_709857c3afd941d581743de29fe34975 } from "@ember/template-compiler";
import LightDarkImg from "discourse/components/light-dark-img";
const CategoryLogo = template_709857c3afd941d581743de29fe34975(`
  <div class="category-logo aspect-image">
    <LightDarkImg
      @lightImg={{@category.uploaded_logo}}
      @darkImg={{@category.uploaded_logo_dark}}
    />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryLogo;
