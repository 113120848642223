import { template as template_b25352de428e44219deeaf7e19710365 } from "@ember/template-compiler";
const FKLabel = template_b25352de428e44219deeaf7e19710365(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
