import { template as template_3e08d49508d94d3b968d94e42a2e9df5 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_3e08d49508d94d3b968d94e42a2e9df5(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
